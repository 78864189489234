.main_bg:before {
    content: "";
    opacity: 0.3;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #000;
}

.MuiCircularProgress-svg {
    color: #FFF;
}

.MuiDialog-root {
    z-index: 1000 !important;
}


.gradient {
    background: linear-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 29.37%, rgba(0, 0, 0, 0) 72.37%, rgba(0, 0, 0, 0.8) 100%);
}